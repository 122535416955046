import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ORBlogHeader from '../../components/headers/ORBlogHeader';
import ORRecentPosts from '../../components/blog/ORRecentPosts';
import ORPostExcerpt from '../../components/blog/ORPostExcerpt';


function Blog() {
    return (
        <>
            <ORBlogHeader></ORBlogHeader>

            <ORRecentPosts></ORRecentPosts>

            <Container className="py-5">
                <Row>
                    <Col md={{span: 8, offset: 2}} lg={{span: 6, offset: 3}}>
                        <ORPostExcerpt post={{createdAt: '01/06/2020', title: 'Lorem Ipsum', link: 'http://localhost:3000/blog/123'}} />
                        <ORPostExcerpt post={{createdAt: '15/06/2020', title: 'Dolor Dolores', link: 'http://localhost:3000/blog/123'}} />
                        <ORPostExcerpt post={{createdAt: '01/07/2020', title: 'Sit Amet Et num', link: 'http://localhost:3000/blog/123'}} />
                        <ORPostExcerpt post={{createdAt: '01/06/2020', title: 'Lorem Ipsum', link: 'http://localhost:3000/blog/123'}} />
                        <ORPostExcerpt post={{createdAt: '15/06/2020', title: 'Dolor Dolores', link: 'http://localhost:3000/blog/123'}} />
                        <ORPostExcerpt post={{createdAt: '01/07/2020', title: 'Sit Amet Et num', link: 'http://localhost:3000/blog/123'}} />
                        <ORPostExcerpt post={{createdAt: '01/06/2020', title: 'Lorem Ipsum', link: 'http://localhost:3000/blog/123'}} />
                        <ORPostExcerpt post={{createdAt: '15/06/2020', title: 'Dolor Dolores', link: 'http://localhost:3000/blog/123'}} />
                        <ORPostExcerpt post={{createdAt: '01/07/2020', title: 'Sit Amet Et num', link: 'http://localhost:3000/blog/123'}} />
                        <ORPostExcerpt post={{createdAt: '01/06/2020', title: 'Lorem Ipsum', link: 'http://localhost:3000/blog/123'}} />
                        <ORPostExcerpt post={{createdAt: '15/06/2020', title: 'Dolor Dolores', link: 'http://localhost:3000/blog/123'}} />
                        <ORPostExcerpt post={{createdAt: '01/07/2020', title: 'Sit Amet Et num', link: 'http://localhost:3000/blog/123'}} />
                    </Col>
                </Row>
            </Container>

        </>
    )
}

export default Blog;