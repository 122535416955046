import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ORPostCard from './ORPostCard';
import Container from 'react-bootstrap/Container';

function ORRecentPosts(props:any) {

    return (
        <Container fluid={true}>
            <h4 className="text-center text-secondary text-uppercase py-3">Artigos em destaque</h4>
            <Row>
                <Col md={{span: 10, offset: 1}} xl={{span: 8, offset: 2}}>
                    <Row>
                        <Col xs={12} sm={4}>
                            <ORPostCard title="E lá se vai 2020. Mas e o freelancing?"/>
                        </Col>
                        <Col xs={12} sm={4}>
                            <ORPostCard title="Migração do legado versus MEAN Stack" />
                        </Col>
                        <Col xs={12} sm={4}>
                            <ORPostCard title="Missão, visão e valor para freelancer" />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}

export default ORRecentPosts;