import React from 'react';
import { FaExternalLinkAlt } from 'react-icons/fa';
import {OREvent} from '../event/OREvent';


function ORExternalLink(props:any) {

    if(props.href && props.label) {

        let gaAction:string = props.gaAction?props.gaAction:props.label;

        return (
            <a onClick={ () => OREvent('Visitor', `ORExternalLink: ${gaAction}`) } className={props.className?props.className:''} target="_blank" rel="noopener noreferrer" href={props.href}>
                {props.label} <sup><FaExternalLinkAlt style={{ fontSize: '1em' }} /></sup>
            </a>
        )
    }
    
    return (
        <sup><FaExternalLinkAlt style={{ fontSize: '1em' }} /></sup>
    )
}

export default ORExternalLink;