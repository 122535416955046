import React from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ReactTypeformEmbed } from 'react-typeform-embed';
import {OREvent} from '../event/OREvent';


class OREstimativa extends React.Component {

    typeformEmbed:any;

    constructor(props:any) {
        super(props);
        this.openForm = this.openForm.bind(this);

    }

    openForm() {
        OREvent('Visitor', 'Typeform button clicked');
        this.typeformEmbed.typeform.open();
    }

    render() {

        return (
            <Container fluid={true} className="text-center bg-primary text-light p-4">
                <Row>
                    <Col xs={8} md={{span:6, offset:3}} className="text-right">
                        <h4>Vamos viabilizar o seu projeto?</h4>
                        <p>
                            Não custa nada avaliar o que precisa ser feito para o seu projeto sair do papel, não é mesmo? 
                            {/* Clique ao lado e rapidamente teremos uma ideia das necessidades e de como dar uma boa solução! */}
                        </p>
                    </Col>
                    <Col xs={4} md={3} className="text-left">
                        <Button size="lg" variant="light" onClick={this.openForm}>
                            Vamos lá!
                        </Button>

                        <ReactTypeformEmbed
                            popup
                            autoOpen={false}
                            url="https://form.typeform.com/to/jxcLe3jb"
                            hideHeaders
                            hideFooter
                            buttonText="Go!"
                            style={{ top: 100 }}
                            ref={(tf:any) => {
                                this.typeformEmbed = tf;
                            }}
                            />

                    </Col>
                </Row>
            </Container>
        )
    }
}

export default OREstimativa;