import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import './ORNavbar.scss';
import ORExternalLink from '../external-link/ORExternalLink';
import {OREvent} from '../event/OREvent';


function ORNavbar() {
    return (
        <div className="or-navbar">
            <Navbar bg="transparent" collapseOnSelect expand="lg">
                <Navbar.Brand onClick={ () => OREvent('Visitor', 'ORNavbar brand clicked') } href="/">
                    {/* <Image className="d-inline-block align-top or-brand-img" src="./favicon.png" /> */}
                    <span className="ml-2">o rodolfo . </span><span className="text-success">online</span>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse className="justify-content-between" id="responsive-navbar-nav">
                    <Nav className="ml-auto"> 
                        <Nav.Link href="/">Home</Nav.Link>
                        {/* <Nav.Link href="/blog">Blog</Nav.Link> */}
                        <Nav.Link href="/tecnologias">Tecnologias</Nav.Link>
                        <Nav.Link href="/servicos">Serviços</Nav.Link>
                        <Nav.Link onClick={ () => OREvent('Visitor', 'ORNavbar behance clicked') }  target="_blank" rel="noopener noreferrer" href="https://www.behance.net/rodoxcwb">Portfólio <ORExternalLink /></Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </div>
    )
}

export default ORNavbar;