import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function ORPostExcerpt(props:any) {

    return (
        <a className="btn btn-outline-light w-100 mb-1" href={props.post.link}>
            <Row className="text-secondary p-2">
                <Col xs={12} md={{span:10, offset:1}} className="text-dark text-left">
                    <small className="text-secondary d-block">{props.post.createdAt}</small>
                    {props.post.title}
                </Col>
            </Row>
        </a>
    )
}

export default ORPostExcerpt;