import React from 'react';
import { FaLinkedin, FaInstagram, FaBehanceSquare,  } from "react-icons/fa";
import './ORSocial.scss';
import {OREvent} from '../event/OREvent';


function ORSocial(props:any) {

    return (
        <span className={props.className}>
            <a onClick={ () => OREvent('Visitor', 'ORSocial instagram clicked') } target="_blank" rel="noopener noreferrer" className="ml-2 mr-2 text-assets or-icons" href="https://www.instagram.com/orodolfo.online"><FaInstagram /></a> 
            <a onClick={ () => OREvent('Visitor', 'ORSocial linkedin clicked') } target="_blank" rel="noopener noreferrer" className="ml-2 mr-2 text-primary or-icons" href="https://www.linkedin.com/in/rodolforickli/"><FaLinkedin /></a> 
            <a onClick={ () => OREvent('Visitor', 'ORSocial behance clicked') } target="_blank" rel="noopener noreferrer" className="ml-2 mr-2 text-primary or-icons" href="https://www.behance.net/rodoxcwb"><FaBehanceSquare /></a> 
            <a onClick={ () => OREvent('Visitor', 'ORSocial workana clicked') } target="_blank" rel="noopener noreferrer" className="ml-2 mr-2 text-primary or-icons" href="https://www.workana.com/freelancer/bd7cbd3d1b20bc5e29d6dbd01f9c0a3c">
                <img className="workana-icon" alt="Workana" src="logo_workana.png" />
            </a> 
            
        </span>
    )
}

export default ORSocial;