import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './ORContact.scss';
import { FaWhatsapp } from "react-icons/fa";
import ORSocial from '../../components/social/ORSocial';
import ORTitle from '../title/ORTitle';
import {OREvent} from '../event/OREvent';

function ORContact(props:any) {

    return (
        <Container className="mt-5 mb-5 text-right">

            <Row>
                <Col xs={12} md={8} lg={9}>

                </Col>

                <Col xs={12} md={4} lg={3}>

                    <ORTitle className="primary">Contato</ORTitle>

                    <address className="pt-4">
                        Telefone:<br /> <FaWhatsapp className="text-success" /> <a onClick={ () => OREvent('Visitor', 'ORContact tel clicked') } href="tel:+5541991981155">(41) 9 9198-1155</a>
                        <br />
                        <br />
                        E-mail:<br /> <a onClick={ () => OREvent('Visitor', 'ORContact mailto clicked') } href="mailto: contato@orodolfo.online">falecom@orodolfo.online</a>
                    </address>

                    <ORSocial />

                </Col>
            </Row>
            

        </Container>
    )
}

export default ORContact;