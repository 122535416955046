import React from 'react';
import './ORTitle.scss';

function ORTitle(props:any) {

    return (
        <h3 className={'or-title ' + props.className + ' ' + (props.size?props.size:'')}>
            <span className={'or-span ' + props.className}>
                {props.children?props.children:'_No_label___'}
            </span>
        </h3>
    )

}

export default ORTitle;