import React, { useEffect } from 'react';
import './App.scss';
import {
    Switch,
    Route,
    useLocation
  } from "react-router-dom";
import Home from './pages/home/Home';
import Blog from './pages/blog/Blog';
import Service from './pages/service/Service';
import Tech from './pages/tech/Tech';
import ORNavbar from './components/navbar/ORNavbar';
import ORFooter from './components/footer/ORFooter';
import OREstimativa from './components/action/OREstimativa';
import ReactGA from 'react-ga';

function usePageViews() {
    let location = useLocation()
  
    useEffect(
      () => {
        ReactGA.pageview(location.pathname);
      },
      [location]
    )
  }

function App() {

    usePageViews()

    return (
        <>
            <ORNavbar />
            <Switch>
                <Route path="/servicos">
                    <Service />
                </Route>
                <Route path="/blog">
                    <Blog />
                </Route>
                <Route path="/tecnologias">
                    <Tech />
                </Route>
                <Route path="/">
                    <Home />
                </Route>
            </Switch>
            <ORFooter>
                <OREstimativa />
            </ORFooter>
        </>
    );
}

export default App;
