import React from 'react';
import Container from 'react-bootstrap/Container';
import './ORPortfolioInfo.scss';
import ORExternalLink from '../external-link/ORExternalLink';
import ORTitle from '../title/ORTitle';

function ORPortfolioInfo(props:any) {
    return (
        <Container className="bg-light p-5 or-container-portfolio ">
            <ORTitle className="assets">Portfólio</ORTitle>

            <div className="text-dark p-3">
                <p>Trabalhos que atuei de ponta ponta estão no Behance. Lá, você verá as seguintes informações:</p>
                
                <ul className="or-list">
                    <li>Protótipos e telas,</li>
                    <li>Tecnologias, recursos utilizados e</li>
                    <li>Serviços prestados para atender o projeto.</li>
                </ul>

            </div>

            <ORExternalLink gaAction="ORPortfolioInfo visualizar trabalhos clicked" href="https://www.behance.net/rodoxcwb" label="Visualizar trabalhos" className="btn btn-sm btn-assets" />

        </Container>
    )
}

export default ORPortfolioInfo;