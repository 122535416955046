import ReactGA from 'react-ga';

export const OREvent = (category:string, action:string) => {

    ReactGA.event({
        category: category, //'User',
        action: action, //'Created an Account'
    });

};
