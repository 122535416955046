import React from 'react';
import ORBio from '../../components/bio/ORBio';
import ORServiceInfo from '../../components/action/ORServiceInfo';
import ORPortfolioInfo from '../../components/action/ORPortfolioInfo';


function Home() {
    return (
        <>
            <ORBio />

            <ORPortfolioInfo />

            <ORServiceInfo />
            
        </>
    )
}

export default Home;