import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FaNodeJs, FaAngular, FaReact, FaSass, FaAndroid, FaApple, FaAws, 
    FaTrello, FaCat, FaCoffee, 
    FaBootstrap, FaDocker, FaHtml5, FaJs, FaLinux, FaJava, FaPhp } from "react-icons/fa";
    
    // FaTools, FaCog, FaCogs, FaRegSun,
    // FaStackOverflow, FaNpm, FaBitbucket, FaGithub, FaUbuntu

function ORToolsIcons() {
    return (
        <>
           <Row>
                <Col className="px-5">
                    <div className="text-center text-assets or-service-icon pt-4">
                        {/* <a className="m-0" href="https://angular.io/" rel="noopener noreferrer" target="_blank"><FaAngular /></a> */}
                        <FaAngular />
                        <FaNodeJs /> <FaJs /> <FaReact /> <FaJava />
                        {/* <FaBitbucket /> <FaGithub /> <FaStackOverflow /> */}
                        <FaApple /> <FaAndroid />
                        <FaHtml5 /> <FaSass /> <FaBootstrap />
                        <FaTrello /> 
                        <FaCoffee /> <FaCat />
                        <FaLinux /> 
                        {/* <FaUbuntu /> */}
                        <FaPhp />
                        <FaDocker /> <FaAws /> 
                        {/* <FaNpm /> */}
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default ORToolsIcons;