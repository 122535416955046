import React from 'react';
import ORContact from '../../components/contact/ORContact';
import ORExternalLink from '../external-link/ORExternalLink';


function ORFooter(props:any) {
    return (
        <>
            {props.children}
            <div className="bg-light text-dark p-2">
                <ORContact />
            </div>
            <div className="bg-secondary text-light text-center p-2">
                <small>
                    &copy; 2020 - Este site foi feito em <strong>React</strong> pelo <strong>Rodolfo</strong> com <u><ORExternalLink className="text-light" href="https://create-react-app.dev/" label="create-react-app" /></u>.
                </small>
            </div>
        </>
    )
}

export default ORFooter;