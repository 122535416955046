import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './Service.scss';
import ORTitle from '../../components/title/ORTitle';
import ORServiceHeader from '../../components/headers/ORServiceHeader';


function Service() {
    return (
        <>
            <ORServiceHeader></ORServiceHeader>

            <Container className="py-2">
               
                <Row>
                    <Col lg={{span:8, offset:4}} className="text-right my-4">
                        <ORTitle className="primary mt-4">Desenvolvimento <i>web</i></ORTitle>
                        
                        <div className="p-3">
                            <p>Produção de sistemas 100% web, utilizando linguagens amplamente utilizadas e atuais como Angular e React. Layouts e templates dentro do padrão de mercado para funcionamento perfeito em qualquer navegador web.</p>
                        </div>

                    </Col>
                </Row>
                <Row>
                    <Col className="bg-light my-4">
                        <ORTitle className="primary mt-4">Desenvolvimento <i>app</i></ORTitle>

                        <div className="p-3">
                            <p>Produção de aplicativos Android e iOS utilizando o framework Ionic.</p>
                            <p>Desenvolvimento em uma única linguagem e distribuição nas principais lojas de aplicativos (Android e iOS).</p>
                        </div>

                    </Col>
                </Row>
                <Row>
                    <Col lg={{span:8, offset:4}} className="text-right my-4">
                        <ORTitle className="secondary mt-4">Integração</ORTitle>

                        <h5 className="text-primary text-uppercase pt-3"><i>Backend</i> de sistemas</h5>
                        <p>As integrações podem ser parte de um projeto de desenvolvimento web, app ou um projeto específico para disponibilizar um backend de serviço B2B, SaaS ou APIs REST.</p>

                    </Col>
                </Row>
                <Row>
                    <Col className="bg-light my-4">
                        <ORTitle className="secondary mt-4">Migração</ORTitle>

                        <Row>
                            <Col lg={8}>
                                <h5 className="text-primary text-uppercase pt-3">Para ambiente <i>cloud</i></h5>
                                <p>Organização e execução da migração de sistemas para <i>a nuvem</i>, seja parcial ou integral.</p>

                                <h5 className="text-primary text-uppercase pt-3">Atualização tecnológica</h5>
                                <p>Projeto de adaptação ou substituição integral ou parcial de sistemas para novas tecnologias mais eficientes, modernas e de manutenção mais simples.</p>
                            </Col>
                        </Row>

                    </Col>
                </Row>
                <Row>
                    <Col lg={{span:8, offset:4}} className="text-right my-4">
                        <ORTitle className="secondary mt-4">Suporte e Capacitação</ORTitle>

                        <h5 className="text-primary text-uppercase pt-3">Suporte</h5>
                        <p>O suporte pode ser parte do processo de encerramento de um projeto ou um contrato para este fim.</p>

                        <h5 className="text-primary text-uppercase pt-3">Capacitação de equipe</h5>
                        <p>Se o sistema requer uma pessoa ou equipe no dia-a-dia operacional, podemos fazer um treinamento específico 
                            para a atividade que precisa ser exercida em cima do novo sistema.</p>

                    </Col>
                </Row>

            </Container>

        </>
    )
}

export default Service;