import React from 'react';
import Container from 'react-bootstrap/Container';
import './Tech.scss';
import ORTools from '../../components/tools/ORTools';
import ORTechHeader from '../../components/headers/ORTechHeader';


function Tech() {
    return (
        <>
            <ORTechHeader></ORTechHeader>

            <Container className="text-center mb-5">
                <ORTools />
            </Container>

        </>
    )
}

export default Tech;