import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container';
import ORSocial from '../../components/social/ORSocial';
import ORExternalLink from '../external-link/ORExternalLink';
import {OREvent} from '../event/OREvent';


function ORBio(props:any) {

    const avatar:string = './avatar01.jpg';

    return (
        <Container className="p-2 mb-4">
            <Row>
                <Col md={{span:8, offset:2}} lg={{span:6, offset:3}} xl={{span:6, offset:3}} className="text-center text-muted">
                    <Image className="m-2 or-header-img" src={avatar} roundedCircle />
                    <ORSocial className="d-block" />
                    <h5>Olá! Eu sou o <strong>Rodolfo Rickli</strong>, 
                        desenvolvedor <strong><i>fullstack</i>, <i>freelancer</i></strong>. 
                    </h5>

                    <p>Desenvolvimento de sistemas em plataforma <i>web</i> e <i>mobile</i> sob medida.</p>

                    <div className="p-2">
                        <a onClick={ () => OREvent('Visitor', 'ORBio /tecnologias clicked') } className="btn btn-sm btn-outline-secondary mr-1 or-w-fixed" href="/tecnologias">Tecnologias</a>
                        <ORExternalLink gaAction="ORBio Portfólio clicked" href="https://www.behance.net/rodoxcwb" label="Portfólio" className="btn btn-sm btn-outline-secondary or-w-fixed" />
                    </div>

                    {/* <div className="pt-4">
                        <a href="/blog">Por que contratar um freelancer vale a pena? Veja aqui!</a>
                    </div> */}

                </Col>
            </Row>
        </Container>
    )
}

export default ORBio;