import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import Container from 'react-bootstrap/Container';
import ORToolsIcons from '../../components/tools/ORToolsIcons';


function ORServiceHeader(props:any) {

    const avatar:string = './avatar02.jpg';

    return (
        <Container className="p-2 mb-2">
            <Row>
                <Col md={{span:8, offset:2}} lg={{span:6, offset:3}} xl={{span:6, offset:3}} className="text-center text-muted">
                    <Image className="m-2 or-header-img bg-light" src={avatar} roundedCircle />
                    <p>Desenvolvimento de sistemas em plataforma <i>web</i> e <i>mobile</i> sob medida!</p>
                    <h5><strong>Serviços</strong></h5>

                </Col>
            </Row>
            <ORToolsIcons />
        </Container>
    )
}

export default ORServiceHeader;