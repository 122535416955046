import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container';

function ORBlogHeader(props:any) {

    const avatar:string = './avatar03.jpg';

    return (
        <Container className="p-2 mb-4">
            <Row>
                <Col md={{span:8, offset:2}} lg={{span:6, offset:3}} xl={{span:6, offset:3}} className="text-center text-muted">
                    <Image className="m-2 or-header-img bg-light" src={avatar} roundedCircle />
                    <p>Conteúdos relacionados ao meu trabalho. Assuntos como gestão, desenvolvimento, tecnologias e <i>insights</i>.</p>
                    <h5><strong>Blog</strong></h5>
                </Col>
            </Row>
        </Container>
    )
}

export default ORBlogHeader;