import React from 'react';
import Image from 'react-bootstrap/Image';
import './ORPostCard.scss';

function ORPostCard(props:any) {

    const avatar:string = './postcard.jpg';

    return (
        <a href="/blog">
            <div className="card mb-3">
                <Image className="card-img-top" src={avatar} />
                <div className="card-body p-2">
                    <span className="text-dark d-block">{props.title || 'Sem título'}</span>
                    <small className="text-secondary d-block">01/05/2020</small>
                </div>
            </div>
        </a>
    )
}

export default ORPostCard;