import React from 'react';

function ORTools() {
    return (
        <>
            
            <h5 className="text-primary text-uppercase mt-3">Linguagens, frameworks e recursos mais utilizados</h5>

            <div className="row">
                <div className="col-12"><small className="text-muted">(framework Spring Boot)</small> Java</div>
                <div className="col-12">Angular</div>
                <div className="col-12">React</div>
                <div className="col-12">Node.js</div>
                <div className="col-12">Loopback <small className="text-muted">(framework Node.js da IBM)</small></div>
                <div className="col-12"><small className="text-muted">(framework, native)</small> Ionic Framework</div>
                <div className="col-12">Bootstrap 4</div>
                <div className="col-12">Docker</div>
                <div className="col-12">Amazon AWS</div>
                <div className="col-12">PHP</div>
                <div className="col-12">MongoDB</div>
                <div className="col-12">MySQL, PostgreSQL, Oracle</div>
            </div>

            <h5 className="text-primary text-uppercase mt-3">IDEs e outros</h5>

            <div className="row">
                <div className="col-12">Visual Code <small className="text-muted">(minha escolha para frontend)</small></div>
                <div className="col-12">Spring Tool Suite/Eclipse IDE</div>
                <div className="col-12"><small className="text-muted">(minha escolha para backend)</small> IntelliJ IDEA</div>
                <div className="col-12">Bitbucket</div>
                <div className="col-12">Android Studio</div>
                <div className="col-12">Adobe XD</div>
                <div className="col-12">Figma</div>
            </div>

            <h5 className="text-primary text-uppercase mt-3">Gestão de Projetos e conceitos ágeis</h5>

            <div className="row">
                <div className="col-12">Scrum</div>
                <div className="col-12">Kanban</div>
                <div className="col-12">Pomodoro</div>
                <div className="col-12"><small className="text-muted">(meu queridinho do momento)</small>Asana</div>
                <div className="col-12"> Trello</div>
            </div>

            <h5 className="text-primary text-uppercase mt-3">Outros</h5>

            <div className="row">
                <div className="col-12">AngularJs, Ionic V1 <small className="text-muted">(sim, cheguei a trabalhar nas primeiras versões)</small></div>
            </div>

            <div className="alert alert-secondary m-3"><strong>Atenção!</strong> <br /> O que você não achou na lista? O que você precisa? Entre em contato! ;-)</div>

        </>
    )
}

export default ORTools;