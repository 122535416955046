import React from 'react';
import Container from 'react-bootstrap/Container';
import ORTitle from '../title/ORTitle';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {OREvent} from '../event/OREvent';

function ORServiceInfo(props:any) {
    return (

        <Container className="p-4 my-5">

            <Row>
                <Col lg={{span:10, offset:2}} className="text-right">

                    <ORTitle className="secondary">Serviços</ORTitle>
                    
                    <div className="text-secondary p-3">
                        
                        <p>Desenvolvimento de sistemas em plataforma <i>web</i> e <i>mobile</i> sob medida!</p>

                        <p>Da fase de concepção da ideia, passando pelo levantamento de requisitos, desenvolvimento até a publicação.<br />
                            Veja onde atuo com mais frequência:</p>

                        <ul className="or-list">
                            <li>Desenvolvimento <i>web</i> e <i>app</i> (híbrido)</li>
                            <li>Desenvolvimento de integrações B2B, SaaS, APIs e outros</li>
                            <li>Migração total ou parcial de sistemas para ambiente em nuvem</li>
                        </ul>
                        
                    </div>

                    <a onClick={ () => OREvent('Visitor', 'ORServiceInfo Ver mais servicos clicked') } className="btn btn-sm btn-outline-secondary" href="/servicos">Ver mais sobre serviços</a>

                </Col>
            </Row>
        </Container>
    )
}

export default ORServiceInfo;